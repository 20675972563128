import styles from './Page.module.css';

const PageNotAvailable = () => {
    return (
        <div className={styles.notaval}>
            <h1>Page Not Available</h1>
        </div>
    );

}

export default PageNotAvailable;